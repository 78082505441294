import {
  displayTypes,
  fontEmphasis,
  Text,
  transforms
} from "@/design/text/Text";
import { getSecondaryColor } from "@/design/colors/Color";

export const timelineMixin = {
  data() {
    return {
      columnHeaderClass: new Text(
        getSecondaryColor(),
        displayTypes.subtitle1,
        fontEmphasis.bold,
        undefined,
        transforms.uppercase
      ).getClassText(),
      columnBodyClass: new Text(
        getSecondaryColor(),
        displayTypes.body2,
        fontEmphasis.regular
      ).getClassText(),
      columnLabelClass: new Text(
        getSecondaryColor(),
        displayTypes.body2,
        fontEmphasis.bold
      ).getClassText()
    };
  }
};
