// design
import {
  displayTypes,
  fontEmphasis,
  Text,
  transforms
} from "@/design/text/Text";
import { Color } from "@/design/colors/Color";
import { iconTask } from "@/design/icon/iconConst";

// model
import {
  dueDateColorClass,
  dueDateTextClass,
  findTaskPriority,
  findTaskState,
  findTaskStateColor,
  findTaskStateColorClass,
  overdueTextClass,
  priorityTextClass,
  taskCreatedColorClass,
  taskPriority,
  taskState
} from "@/model/workflow/task/taskModel";

// filters
import { toLocalDateTimeString } from "@/filters/dateFilter";

// utils
import { isValidDate } from "@/utils";

// services
import {
  taskDueDateLocalDateTime,
  taskStatusTooltip
} from "@/services/inbox/taskService";

// mixins
import { timelineMixin } from "@/mixins/shared/timeline/timelineMixin";
import {
  projectContextMenuItem,
  projectTimelineMenuItems
} from "@/services/project/projectService";
import { isMobile } from "@/services/dom/windowService";
import { projectNavigatable } from "@/mixins/shared/navigatable/projectNavigatable";
import { folderRecordNavigatable } from "@/mixins/shared/navigatable/folderRecordNavigatable";

export const timelineTaskMixin = {
  mixins: [timelineMixin, projectNavigatable, folderRecordNavigatable],
  data() {
    return {
      iconTask: iconTask,
      taskCreatedColorClass: taskCreatedColorClass(),
      visibleDialogTaskData: false,
      selectedTask: undefined,
      projectTimelineMenuItems: undefined,
      visibleDialogProjectExport: false
    };
  },
  methods: {
    /**
     * get Status Color Class
     * @param task
     * @return {string}
     */
    getStatusColorClass(task) {
      return findTaskStateColorClass(task?.status ?? -1);
    },

    /**
     * Get Task Priority
     * @param task
     * @return {string}
     */
    getTaskPriority(task) {
      return findTaskPriority(task?.priority ?? -1)?.text ?? "?";
    },

    /**
     * task Status Text Class
     * @param task
     * @return {string}
     */
    taskStatusTextClass(task) {
      const stateColor = findTaskStateColor(task?.status ?? -1);
      const color = stateColor
        ? new Color(
            stateColor.name,
            stateColor.variantType,
            stateColor.variantNumber
          )
        : undefined;

      return new Text(
        color,
        displayTypes.subtitle1,
        fontEmphasis.bold,
        undefined,
        transforms.uppercase
      ).getClassText();
    },

    /**
     * Get Task Status
     * @param task
     * @return {string|string}
     */
    getTaskStatus(task) {
      return findTaskState(task?.status ?? -1)?.name ?? "";
    },

    /**
     * Get Task Name
     * @param task
     * @return {*|string}
     */
    getTaskName(task) {
      return task?.name ?? "?";
    },

    /**
     * Get Task Assignee
     * @param task
     * @return {string|*}
     */
    getTaskAssignee(task) {
      const actorId = task?.actorId ?? -1;
      const actorName = task?.actorName;

      // make sure whether a user has been assigned (manually/auto/self)
      if (actorId > 0) {
        // a user has been assigned (manually/auto/self)
        return actorName;
      }

      return task?.assignedTo;
    },

    /**
     * Get Task DueDate
     * @param task
     * @return {*|string}
     */
    getTaskDueDate(task) {
      return taskDueDateLocalDateTime(task);
    },

    /**
     * has Due Date
     * @param task
     * @return {boolean} true if a task has Due Date
     */
    hasDueDate(task) {
      return !(task?.isMilestone ?? false) && !!task?.dueDate;
    },

    /**
     * determine Task Duration visibility
     * @param task
     * @return {boolean} true if Task Duration is visible
     */
    visibleDuration(task) {
      return !(task?.isMilestone ?? false);
    },

    /**
     * determine Task Milestone visibility
     * @param task
     * @return {boolean} true if Task Milestone is visible
     */
    visibleMilestone(task) {
      return !!(task?.isMilestone ?? false);
    },

    /**
     * determine Task Due visibility
     * @param task
     * @return {boolean}
     */
    visibleTaskDue(task) {
      return !!isValidDate(task?.dueDate);
    },

    /**
     * determine Task Overdue visibility
     * @param task
     * @return {boolean}
     */
    visibleTaskOverdue(task) {
      return task?.isOverdue ?? false;
    },

    /**
     * determine Task Status Date visibility
     * @param task
     * @return {boolean}
     */
    visibleTaskStatusDate(task) {
      return task.status !== taskState.notStarted;
    },

    /**
     * due Date Label Text Class
     * @return {String|string}
     */
    dueDateTextClass(task) {
      return dueDateTextClass(task, displayTypes.body2, fontEmphasis.bold);
    },

    /**
     * due Date Color Class
     * @param task
     * @return {String|string}
     */
    dueDateColorClass(task) {
      return dueDateColorClass(task);
    },

    /**
     * overdue Label Text Class
     * @return {String|string}
     */
    overdueTextClass(task) {
      return overdueTextClass(task, displayTypes.body2, fontEmphasis.bold);
    },

    /**
     * priority Text Class
     * @return {String|string}
     */
    priorityTextClass(task) {
      const font =
        (task?.priority ?? taskPriority.normal) === taskPriority.high
          ? fontEmphasis.bold
          : fontEmphasis.regular;
      return priorityTextClass(task, displayTypes.body2, font);
    },

    /**
     * Get Status Changed Date
     * @param task
     * @return {string|string}
     */
    getStatusChangedDate(task) {
      return toLocalDateTimeString(task.statusChanged);
    },

    /**
     * Get Task Duration
     * @param task
     * @return {*|string}
     */
    getTaskDuration(task) {
      return task?.duration ?? "Not Specified";
    },

    /**
     * task Status Tooltip
     * @param task
     * @return {string}
     */
    taskStatusTooltip(task) {
      return taskStatusTooltip(task);
    },

    /**
     * Get Task Created Date
     * @param task
     * @return {*|string}
     */
    getTaskCreatedDate(task) {
      return toLocalDateTimeString(task?.created);
    },

    /**
     * Get Task Effective Date
     * @param task
     * @return {string}
     */
    getTaskEffectiveDate(task) {
      return toLocalDateTimeString(task?.effective) ?? "";
    },

    /**
     * Close Task Data Dialog
     */
    closeTaskDataDialog() {
      this.visibleDialogTaskData = false;
    },

    /**
     * handle close Dialog Export Project
     */
    closeDialogExportProject() {
      this.visibleDialogProjectExport = false;
    },

    /**
     * On Task Icon Clicked
     * @param task
     */
    onTaskSelected(task) {
      this.selectedTask = task;
      this.visibleDialogTaskData = true;
    },

    /**
     * Context Menu Open Event
     * @param event
     * @param task
     */
    openContextMenu(event, task) {
      try {
        console.log(
          `${this.$options.name}  openContextMenu() isMobileDevice`,
          isMobile()
        );

        if (!isMobile()) {
          // Save right-clicked record data
          this.selectedTask = task;
          event.preventDefault();
          this.$refs.menu.open(event);
        } else {
          event.preventDefault();
          return false;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Event on Context Menu Action
     * @param payload
     */
    performAction(payload) {
      try {
        switch (payload.action.name) {
          case projectContextMenuItem.ShowDetails:
            this.visibleDialogTaskData = true;
            break;

          case projectContextMenuItem.JumpToTask:
            this.$router.push(this.taskRouteLocation(this.selectedTask.taskId));
            break;

          case projectContextMenuItem.JumpToRecord:
            this.$router.push(
              this.folderModuleRecordRouteLocation(this.record)
            );
            break;

          case projectContextMenuItem.Export:
            this.visibleDialogProjectExport = true;
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  watch: {
    selectedTask() {
      const task = this.project.userTasks.find(
        task => task.taskId === this.selectedTask.taskId
      );

      this.projectTimelineMenuItems = projectTimelineMenuItems(
        task,
        this.principal?.actorId ?? -1
      );
    }
  }
};
